import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ComponentCommunicationService } from '../componentcommunication/component-communication.service';

interface TimedData<T> {
  time: number;
  data: T;
}

export interface ITypedStorage {
  setItem<T>(key: string, value: T): void;
  getItem<T>(key: string): T;
}

class TypedLocalStorage implements ITypedStorage {
  public setItem<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public getItem<T>(key: string): T {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }
}

export class TimedStorage {
  private currentTime = () => Date.now();
  private devMode = false;
  constructor(private storage: ITypedStorage) {}

  public setDevMode(devMode: boolean) {
    this.devMode = devMode;
  }

  public set<T>(key: string, value: T, ttl: number) {
    const timedData: TimedData<T> = { time: Date.now() + ttl, data: value };
    this.storage.setItem(key, timedData);
  }

  public getValueNotCheck<T>(key: string) {
    if (this.devMode) {
      return null;
    }
    const timedData: TimedData<T> = this.storage.getItem(key);
    return timedData.data;
  }
  public hasValueAndIsNotExpired(key: string) {
    if (this.devMode) {
      return false;
    }
    const timedData: TimedData<any> = this.storage.getItem(key);
    if (!timedData) {
      return false;
    }
    const hasExpired = timedData.time < this.currentTime();
    return !hasExpired;
  }
}

@Injectable({
  providedIn: 'root',
})
export class HttpClientCacheService {
  private readonly cache: TimedStorage;
  constructor(
    private httpClient: HttpClient,
    private communicationService: ComponentCommunicationService,
  ) {
    this.cache = new TimedStorage(new TypedLocalStorage());
    this.communicationService.devMode.subscribe((devMode) => {
      this.cache.setDevMode(devMode);
    });
  }

  public async get<T>(url: string, ttl: number): Promise<T> {
    if (this.cache.hasValueAndIsNotExpired(url)) {
      return this.cache.getValueNotCheck(url);
    }
    const response = await lastValueFrom(this.httpClient.get<T>(url));
    this.cache.set(url, response, ttl);
    return response;
  }
  public async post<T>(url: string, body: any, ttl: number): Promise<T> {
    if (this.cache.hasValueAndIsNotExpired(url)) {
      return this.cache.getValueNotCheck(url);
    }
    const response = await lastValueFrom(this.httpClient.post<T>(url, body));
    this.cache.set(url, response, ttl);
    return response;
  }
  public async put<T>(url: string, body: any, ttl: number): Promise<T> {
    if (this.cache.hasValueAndIsNotExpired(url)) {
      return this.cache.getValueNotCheck(url);
    }
    const response = await lastValueFrom(this.httpClient.put<T>(url, body));
    this.cache.set(url, response, ttl);
    return response;
  }
}
